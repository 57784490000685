.InputGroup {
  margin-bottom: 15px;
}

.InputGroup label,
.InputGroup span,
.InputGroup input,
.InputGroup textarea {
  display: block;
}

.InputGroup input,
.InputGroup select,
.InputGroup textarea {
  margin: 8px 0;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 10px;
  background-color: var(--color-primary);
  color: var(--color-background);
}

.InputGroup span {
  font-size: 12px;
  color: var(--color-primary);
}

.InputGroup span a {
  color: var(--color-accent);
}

.InputGroup label {
  color: var(--color-primary);
}

.InputGroup textarea {
  resize: none;
  height: 50vh;
}
